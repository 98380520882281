import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import HeroSection from "components/HeroSection";

const useStyles = makeStyles((theme) => ({
    spanHead: {
        marginRight: theme.spacing(3),
        background: 'linear-gradient(90.71deg, #8270DB 39.46%, #003E8C 99.73%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
    },
    typography:{
        fontFamily:theme.typography['h1-medium'].fontFamily,
        fontSize: "57px",
        fontWeight: 500,
        lineHeight: "68.4px",
        textAlign: "left",
        [theme.breakpoints.down("md")]: {
           fontSize: "48px",
           lineHeight: "59.52px",
          }
    }
}));


export default function Main({ onExperienceLive }) {
    const classes = useStyles();

    const title = (
        <Box>
            <Typography className={classes.typography} >
                Teach concepts faster & provide personalized feedback with 
            </Typography>
            <Typography className={`${classes.spanHead} ${classes.typography}`}>
                Teach & Train
            </Typography>
        </Box>
    );

    return (
        <HeroSection 
            title={title} 
            img={[`${process.env.REACT_APP_ASSETS_URL}/images/t&t_main.png`]}
            onExperienceLive={onExperienceLive} 
        />
    );
}