import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import AppShell from "components/AppShell";
import Button from "components/Button";

const useStyles = makeStyles((theme) => ({
  parentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 104px)",
  }, container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    ...theme.typography['body02-semiBold'],
    height: 40,
  }
}));

function NotFound() {
  const classes = useStyles();

  return (
    <AppShell>
      <Box className={classes.parentContainer}>
        <Box className={classes.container}
        >
          <Typography
            variant="h6-medium"
            color="#2C3840"
            fontWeight={600}
            mb="3em"
          >
            The page you are looking for is not found
          </Typography>
          <Box textAlign="center">
            <Typography
              variant="h5"
              color="#D8D8D8"
              fontWeight={600}
              mb={-5}
            >
              Error
            </Typography>
            <Typography
              variant="h1"
              color="#D8D8D8"
              fontFamily="Sora"
              fontSize={300}
            >
              404
            </Typography>
          </Box>
          <a style={{ textDecoration: "none" }} href="/">
            <Button
              variant="contained"
              className={classes.button}
            >
              Go Back to Homepage
            </Button>
          </a>
        </Box>
      </Box>
    </AppShell>
  );
}

export default NotFound;
