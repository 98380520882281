import React from "react";
import { Box , Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';

import CircularProgressCountDown from "./CircularProgressCountDown";
import Chip from "./Chip";
import InteractiveDemoDialog from "dialogs/InteractiveDemoDialog";
import CustomButton from "./CustomButton";
import './demo.css';
import { InprepFields, TNT_Fields } from "./Constant";
import { SimpleSelect } from "./CustomSelectField";
import {trackCustomEvent } from "utils";
import FeedbackDialog from "./FeedbackDialog";


const useStyles = makeStyles((theme) => ({
  headerContainer: {
    width: '100%',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    padding:theme.spacing(0,37.5),
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url('https://languify-assets.s3.ap-south-1.amazonaws.com/images/Cubes.png')`,
      backgroundRepeat: 'repeat',
      opacity: 0.5,
      zIndex: 0,
    },
    [theme.breakpoints.down('lg')]:{
      padding:theme.spacing(0,15)
    },
    [theme.breakpoints.between('sm','md')]:{
      padding:theme.spacing(0,10),
      height: '120px',
    },
    [theme.breakpoints.down('sm')]:{
      padding:theme.spacing(0,5),
        height: '100px'
    },
  },  
  header: {
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '29px !important',}
  },
  innerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spacing(1),
    background: 'linear-gradient(278.27deg, #4B6CB7 0%, #002759 112.69%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    position: 'relative',
    zIndex: 1,
    backgroundClip: 'text',
    textFillColor: 'transparent',
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      textAlign: "center",
    },
  },
  chip: {
    borderRadius: '12px',
    padding: theme.spacing(2, 4),
  },
  headContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(6),
    marginBottom: theme.spacing(11),
    [theme.breakpoints.down('sm')]:{
      marginBottom: theme.spacing(5),
    }
  },
  SecondheadContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(15, 37.5),
    width: '100vw',
    background: 'linear-gradient(278.27deg, #4B6CB7 0%, #002759 112.69%), #FFFFFF',
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down('lg')]:{
      padding: theme.spacing(15, 15),
    },
    [theme.breakpoints.down('md')]:{
      padding: theme.spacing(7, 10),
    },
    [theme.breakpoints.down('sm')]:{
      padding: theme.spacing(7, 5),
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
  },
  content: {
    margin: theme.spacing(0, 0, 3, 0),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      textAlign: "left",
    },
  },
  dropdown: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    justifyContent:"center",
    gap:theme.spacing(10),
    width: "100%",
  },
  options: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(10),
    gap: theme.spacing(10),
  },
  startBtn: {
    width: "449px",
    height: "48px",
    borderRadius: "4px",
    cursor: 'pointer',
  },
  BoxContainer: {
    width: "753px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    border: '1px solid #FFFFFF',
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  BlueBox: {
    display: 'flex',
    width: "100%",
    gap: theme.spacing(10),
    flexDirection: 'column',
    justifyContent:"center",
    alignItems:"center",
    padding: theme.spacing(15, 32),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(10),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(10, 4),
    },
  },
  whiteBox: {
    width: "100%",
    padding: theme.spacing(10, 32),
    backgroundColor: "rgba(245, 245, 245)",
    borderRadius: "0px 0px 15px 15px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url('https://languify-assets.s3.ap-south-1.amazonaws.com/images/Square+purple+pattern.png')`,
    backgroundRepeat: 'repeat',
    zIndex: 1,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(10, 10),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(10, 4),
    },
  },
  welcomeContainer: {
    width: "753px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    border: '1px solid #FFFFFF',
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  BlueBoxwelcomeContainer: {
    marginTop: "60px",
    width: "100%",
    marginBottom: theme.spacing(6),
    backgroundColor: "transparent",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: "center"
  },
  whiteBoxwelcomeContainer: {
    width: "100%",
    padding: theme.spacing(4, 0),
    backgroundColor: "rgba(245, 245, 245)",
    borderRadius: "0px 0px 15px 15px",
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: theme.spacing(6),
    },
  },
}));


export default function Demo({ elementToScrollRef , b2c=false }) {
  const classes = useStyles();
  const theme = useTheme();

  const location = useLocation();

  const [jobRole, setJobRole] = React.useState({ _id:"", name:"", template:""});
  const [level, setLevel] = React.useState({ _id:"", name:""});

  const [topics, setTopics] = React.useState([]);
  const [difficultyLevels, setDifficultyLevels] = React.useState([]);
  const [template, setTemplate] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const [countdown, setCountdown] = React.useState(false);
  const [showDemo, setShowDemo] = React.useState(true);
  const [recentFeedbackUrl, setRecentFeedbackUrl] = React.useState("");
  const [openFeedback, setOpenFeedback] = React.useState(false);

  const isTeachAndTrain = React.useMemo(() => (location.pathname === '/teach&train') ? true : false, []);
  const educationUseCase = React.useMemo(() => (location.pathname === '/usecase/education') ? true : false, []);
  const isInprep = React.useMemo(() => (location.pathname === '/inprep') ? true : false, []);
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);

  React.useEffect(() => {
    if (isTeachAndTrain || educationUseCase) {
      setIsButtonDisabled(!(template && topics && difficultyLevels));
    } else if (isInprep) {
      setIsButtonDisabled(!(jobRole.name && level.name));
    } else {
      setIsButtonDisabled(!(jobRole.name && level.name));
    }
  }, [isTeachAndTrain, isInprep, template, jobRole, level.name]);

  const handleJobRoleSelect = (e) => {
    const jobRoleId = e.target.value;
    const selectedJobRole = InprepFields.jobRole.find(role => role._id === jobRoleId);
    
    if (selectedJobRole) {
      setJobRole(selectedJobRole);
      setTemplate(selectedJobRole.template);
    } else {
      setJobRole({ _id:"", name:"", template:""});
    }
  };

  const handleLevelSelect = (e) => {
    const levelId = e.target.value;
    const selectedLevel = InprepFields.level.find(l => l._id === levelId);

    if (selectedLevel) {
      setLevel(selectedLevel);
    } else {
      setLevel({});
    }
  };

  const handleSelectDomain = (e) => {
    const domainId = e.target.value;
    const selectedDomain = TNT_Fields.domains.find(domain => domain._id === domainId);

    if (selectedDomain) {
      setTopics(selectedDomain);
    } else {
      setTopics([]);
    }
  };

  const handleSelectDifficulty = (e) => {
    const topicId = e.target.value;
    const selectedTopic = topics.topics.find(topic => topic._id === topicId);

    if (selectedTopic) {
      setDifficultyLevels(selectedTopic);
    } else {
      setDifficultyLevels([]);
    }
  };

  const handleSelectTemplate = (e) => {
    const templateId = e.target.value;
    const selectedTemplate = difficultyLevels.difficultyLevels.find(t => t._id === templateId);

    if (selectedTemplate) {
      setTemplate(selectedTemplate);
    } else {
      setTemplate("");
    }
  };

  const handleDialogClose = (attempted) => {
    if (attempted) setShowDemo(false);
    setOpen(false);
  };

  React.useEffect(() => {
    if (localStorage.getItem("recent-demo-feedback-url")) {
      setRecentFeedbackUrl(localStorage.getItem("recent-demo-feedback-url"));
    }
  }, [open, showDemo]);

  const setPopupOpen = () => {
    setCountdown(true);
  };

  const handleClose = () => {
    setCountdown(false);
  };
  
  return (
    <Box ref={elementToScrollRef}>
      {
        (isTeachAndTrain || educationUseCase) ?
          <div className={classes.headerContainer}>
            <div className={classes.innerBox}>
              <Typography className= {classes.header} variant="Miscellaneous" color="neutral.clr-900">
                Step Into the Future of Education and Training
              </Typography>
            </div>
          </div>
          : isInprep ?
            <div className={classes.headerContainer}>
              <div className={classes.innerBox}>
                <Typography className= {classes.header} variant="Miscellaneous" color="neutral.clr-900">
                  Prepare your students for interviews with AI
                </Typography>
              </div>
            </div>
            : <div className={classes.headerContainer}>
              <div className={classes.innerBox}>
                <Typography className= {classes.header} variant="Miscellaneous" color="neutral.clr-900">
                  Experience revolutionary AI learning solution
                </Typography>
              </div>
            </div>

      }

      <div id="demo" className={classes.container}>

        <div className={classes.headContainer}>
          <div className={classes.chip}>
            <Chip content={"How our AI works"}
              color={"neutral.clr-900"} bgColor={"#E5F1FF"}
              startIcon=
              {
                <img src={`${process.env.REACT_APP_ASSETS_URL}/images/demo_thinking_face.svg`}
                  alt="Icon"
                />
              }
            />
          </div>
          <div className={classes.SecondheadContainer} >
            <Typography variant="h3-medium" color="shades.clr-white-900" className={classes.title}>
              Talk to our AI
            </Typography>
            <Typography variant="h6-regular" className={classes.subtitle} 
            color="#CCD4DE">
              It takes less than 2 minutes
            </Typography>
          </div>
        </div>

        {showDemo ? (
          <>
            <Box className={classes.BoxContainer}>
              <Box className={classes.BlueBox}>

                {(isTeachAndTrain || educationUseCase) ? (
                    <Box className={classes.dropdown}>
                      <SimpleSelect
                        options={TNT_Fields.domains}
                        value={topics._id}
                        onSelect={handleSelectDomain}
                        placeholder="Select domain"
                        label="I want questions from domain..."
                      />
                      <SimpleSelect
                        options={topics?.topics ?? []} 
                        value={difficultyLevels._id}
                        onSelect={handleSelectDifficulty}
                        disabled={!topics?.topics || topics.topics.length === 0}  
                        placeholder="Select topic"
                        label="And the topic should be..."
                      />
                      <SimpleSelect
                        options={difficultyLevels?.difficultyLevels ?? []}
                        placeholder="Select difficulty level"
                        value={template._id}
                        onSelect={handleSelectTemplate}
                        disabled={difficultyLevels.length === 0}
                        label="With the difficulty level of..."
                      />

                    </Box>
                ) : (
                  <Box  className={classes.dropdown} >
                      <SimpleSelect
                        options={InprepFields.jobRole}
                        value={jobRole._id}
                        onSelect={handleJobRoleSelect}
                        placeholder="Select job role"
                        label="Preparing for"
                      />
                      <SimpleSelect
                        options={InprepFields.level}
                        value={level._id}
                        onSelect={handleLevelSelect}
                        placeholder="Select experience level"
                        label="Your Experience Level"
                      />
                  </Box>
                )}
              </Box>

              <Box className={classes.whiteBox} >
                <CustomButton
                  variant="contained"
                  disabled={isButtonDisabled}
                  size="large"
                  onClick={() => {
                    trackCustomEvent('start_interviewing');
                    setPopupOpen(true);
                  }}
                  style={{width: '496px',zIndex:4,
                  backgroundColor: isButtonDisabled ? theme.palette.neutral['clr-200'] : '',
                  color: isButtonDisabled ? theme.palette.neutral['clr-600'] : '',}}>
                  Start Interview
                </CustomButton>

                {countdown ? (
                  <CircularProgressCountDown
                    proceedToTest={() => {
                      setOpen(true);
                    }}
                    onDialogClose={() => {
                      handleClose();
                    }}
                  />
                ) : null}
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box className={classes.welcomeContainer}>
              <Box className={classes.BlueBoxwelcomeContainer}>
                <Typography variant="h3-medium" color='shades.clr-white-900' >
                  Welcome back! Interested to know more?
                </Typography>
                <Typography variant="h6-medium" color='shades.clr-white-900'
                  margin="28px" >
                  Book a meeting with us. We would love to learn more about your Business <br />
                  needs and discuss how best we can partner with you.
                </Typography>
              </Box>
              <Box className={classes.whiteBoxwelcomeContainer}>
                <Box display="flex" justifyContent="center" alignItems="center" gap={2} >
                  <CustomButton
                    variant="outlined"
                    size="medium"
                    onClick={() => setShowDemo(true)}
                    >
                    Try once more
                    </CustomButton>
                  {!b2c && <CustomButton
                    variant="contained"
                    size="medium"
                    onClick={() => {
                      trackCustomEvent('book_a_meeting_inprep');
                      window.open(process.env.REACT_APP_GET_IN_TOUCH_TALLY_FORM_URL, '_self')
                    }}
                    >
                    Book a Call
                    </CustomButton>
                    }
                </Box>
                <Box display="flex" justifyContent="center" pt={4}>
                    <Typography variant="body01-bold" color='#1961BF' textAlign='center'
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={()=>setOpenFeedback(true)} 
                    >
                      Check out Past Analysis Report
                    </Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
        <InteractiveDemoDialog
          open={open}
          details={{
            jobRole:jobRole.name, template: (isTeachAndTrain || educationUseCase) ? 
            template.template : template, level: level.name
          }}
          onClose={handleDialogClose}
          isTeachAndTrain={isTeachAndTrain || educationUseCase}
          isB2C={b2c}
          setOpenFeedback={setOpenFeedback}
        />
        <FeedbackDialog
          openDialog={openFeedback}
          setOpenDialog={()=>setOpenFeedback(null)}
          url={recentFeedbackUrl}
        />
      </div>
    </Box>
  );
}
