import React from "react";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Close from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";

import { useFormik } from "formik";
import { Divider, IconButton } from "@mui/material";
import { TextFieldWithLabel } from "components/CustomTextField";
import { CustomLoadingButton } from "components/CustomButton";
import { SimpleSelectWithLabel } from "components/SelectFieldWithLabel";
import { addB2cFreeTrialUser } from "services/userService";
import { useSnackbar } from "contexts/SnackbarProvider";

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex", width: '100%',
        alignItems: "left",
        gap: 20,
        flexDirection: 'column',
        marginTop: '30px',
        marginBottom: '20px',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column', alignItems: "center"
        },
    },
    nextButton: {
        textTransform: 'none',
        height: '45px',
        ...theme.typography['h6-medium']
    }
}));

const FormValidationSchema = yup.object({
    name: yup.string().required("Please enter your name").trim(),
    email: yup.string().email("Please enter a valid email")
        .required("Please enter your email").trim(),
    contact: yup.string().required("Please enter your contact information")
        .min(10, "Please enter a valid contact").trim(),
    collegeName: yup.string().required("Please enter your college name"),
    aboutus: yup.string().required("Please select an option"),
});

const Aboutus = [
    { _id: 'social_media', name: 'Social Media' },
    { _id: 'search_engine', name: 'Search Engine' },
    { _id: 'referral', name: 'Referral' },
    { _id: 'other', name: 'Other' },
]

export default function FreeTrialDialog({ open, onClose }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const [loading, setLoading] = React.useState(false);

    const formik = useFormik({
        initialValues: { name: "", email: "", contact: "", collegeName: "", aboutus: "" },
        validationSchema: FormValidationSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                await addB2cFreeTrialUser({
                    name: values.name,
                    email: values.email,
                    contact: values.contact?.toString(),
                    metadata: {
                        collegeName: values.collegeName,
                        aboutus: values.aboutus
                    }
                });

                snackbar.showSnackbar('Your account has been successfully created', 'success');
                onClose(true);
            } catch (error) {
                console.error(error);
                snackbar.showSnackbar(error?.message, 'error');
            } finally {
                setLoading(false);
            }
        },
    });


    return (
        <Dialog open={open} onClose={onClose} fullWidth keepMounted>
            <DialogContent style={{ position: 'relative', borderRadius: '8px' }}>
                <Typography variant="h3-semiBold">
                    Start your free trial now
                </Typography>
                <Divider />
                <IconButton
                    onClick={onClose}
                    style={{ position: "absolute", top: 16, right: 15 }}
                >
                    <Close />
                </IconButton>

                <Box className={classes.cardContainer}>
                    <TextFieldWithLabel
                        id='name'
                        name='name'
                        label='Full name'
                        placeholder='Your full name'
                        required
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={(formik.touched.name && !!formik.errors.name) ? formik.errors.name : ''}
                    />
                    <Box display='flex' justifyContent='space-between' gap={5}>

                        <TextFieldWithLabel
                            id='contact'
                            name='contact'
                            label='Contact number'
                            type='number'
                            required
                            placeholder='+91 XXXXXXXXXX'
                            value={formik.values.contact}
                            onChange={formik.handleChange}
                            error={
                                (formik.touched.contact && !!formik.errors.contact) ?
                                    formik.errors.contact : ''}
                        />

                        <TextFieldWithLabel
                            id='email'
                            name='email'
                            label='Mail id'
                            placeholder='example@gmail.com'
                            required
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={
                                (formik.touched.email && !!formik.errors.email) ?
                                    formik.errors.email : ''}
                        />
                    </Box>
                    <TextFieldWithLabel
                        id='collegeName'
                        name='collegeName'
                        label='College name'
                        required
                        placeholder='Your college name'
                        value={formik.values.collegeName}
                        onChange={formik.handleChange}
                        error={
                            (formik.touched.collegeName && !!formik.errors.collegeName) ?
                                formik.errors.collegeName : ''}
                    />

                    <SimpleSelectWithLabel
                        options={Aboutus}
                        name="aboutus"
                        placeholder="Choose an option"
                        label="How do you know about Interview Wizard"
                        onChange={formik.handleChange}
                        required
                        value={formik.values.aboutus}
                        error={
                            (formik.touched.aboutus && !!formik.errors.aboutus) ?
                                formik.errors.aboutus : ''}
                        sx={{ width: '100%' }}
                        menuStyle={{ width: "100%" }}
                    />

                    <CustomLoadingButton
                        onClick={formik.handleSubmit}
                        type="button"
                        className={classes.nextButton}
                        loading={loading}
                    >
                        Submit
                    </CustomLoadingButton>
                </Box>
            </DialogContent>
        </Dialog>
    );
}