import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { setupIntersectionObserver } from 'utils/observerUtil';


const useStyles = makeStyles((theme) => ({
  clientContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop:`2px solid ${theme.palette.divider}`,
    borderBottom:`2px solid ${theme.palette.divider}`,
    height: '100%',
    width: '100%',
    padding:theme.spacing(15,37.5,23,37.5),
    gap: theme.spacing(23),
    transition: 'transform 0.4s ease-in-out, opacity 1s ease-in-out',
    transform: 'translateY(50px)',
    opacity: 0,
    overflow:"hidden",
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(15,15),
     },
     [theme.breakpoints.down("md")]: {
      padding: theme.spacing(6,10,9.5,10),
     },
     [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(6,5,9.5,5),
     }
  },
  backgroundImage: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'url("https://assets.languify.in/images/square_white.png")',
    backgroundRepeat: 'repeat',
    backgroundSize: 'auto',
    opacity: 0.4,
    zIndex: -1,
  },
  clientBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(23),
    width:"100%",
    overflow:"hidden",
    [theme.breakpoints.down("800")]: {
      gap: theme.spacing(11),
     },
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width:"100%"
  },
  gridRow: {
    display: 'flex',
    width:"100%"
  },
  gridItem: {
    width:"33%",
    height: '104px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px dashed',
    borderColor: theme.palette.neutral.clr_400,
    [theme.breakpoints.between("md","lg")]: {
      width:"33%"
     },
  },
  image:{
    width : '170px',
    [theme.breakpoints.between("sm","md")]: {
      width:"120px"
     },
    [theme.breakpoints.down("sm")]: {
      width:"100px"
     },
  }
}));

const ClientsSection = () => {
  const classes = useStyles();

  const [visible, setVisible] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    if (window.innerHeight > 1700) {
      setVisible(true);
      return; 
    }

    const cleanup = setupIntersectionObserver(containerRef, setVisible, 0.2)

    return cleanup;

  }, []);

  const boxes = [
    {
      image: `${process.env.REACT_APP_ASSETS_URL}/images/home_client_geekster.png`,
    },
    {
      image: `${process.env.REACT_APP_ASSETS_URL}/images/home_client_upGrad.png`,
    },
    {
      image: `${process.env.REACT_APP_ASSETS_URL}/images/home_client_academix.png`,
    },
    {
      image: `${process.env.REACT_APP_ASSETS_URL}/images/home_client_careerboat.png`,
    },
    {
      image: `${process.env.REACT_APP_ASSETS_URL}/images/home_client_kudoswall.png`,
    },
    {
      image: `${process.env.REACT_APP_ASSETS_URL}/images/home_client_functionup.png`,
    },
  ];

  return (
    <Box
      ref={containerRef}
      className={classes.clientContainer}
      style={{
        transform: visible ? 'translateY(0)' : 'translateY(50px)',
        opacity: visible ? 1 : 0,
      }}
    >
      <Box className={classes.backgroundImage} />
      <Box className={classes.clientBox}>
        <Typography variant="h2-medium" color="neutral.clr-900">
          Trusted by 30+ Institutes
        </Typography>
        <Box className={classes.gridContainer}>
          {[0, 1].map((row) => (
            <Box className={classes.gridRow} key={row}>
              {boxes.slice(row * 3, row * 3 + 3).map((box, index) => {
                const isTopRow = row === 0;
                const isBottomRow = row === 1;
                const isFirstColumn = index === 0;
                const isLastColumn = index === 2;

                return (
                  <Box
                    key={index}
                    className={classes.gridItem}
                    style={{
                      borderTop: isTopRow ? '1px dashed transparent' : `1px dashed #8692A3`,
                      borderBottom: isBottomRow ? '1px dashed transparent' : `1px dashed #8692A3`,
                      borderLeft: isFirstColumn ? '1px dashed transparent' : `1px dashed #8692A3`,
                      borderRight: isLastColumn ? '1px dashed transparent' : `1px dashed #8692A3`,
                    }}
                  >
                    <img className={classes.image} src={box.image} alt="Client" />
                  </Box>
                );
              })}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ClientsSection;
