import React from "react";
import AppShell from "components/AppShell";
import BackedBy from "components/BackedBy";
import Main from "./Main";
import Faq from "components/Faq";

import { useLocation } from "react-router-dom";
import Problems from "./Problem";
import WithLanguify from "./WithLanguify";
import Automate from "./Automate";
import ClientsSection from "components/ClientsSection";
import SolutionSection from "components/SolutionSection";
import Demo from "components/Demo";

const items = [
  {
      img : `${process.env.REACT_APP_ASSETS_URL}/images/home_teacher_student.png`,
      title: 'Unlimited mock interviews - at no extra cost',
      subText: `Students can practice as many times as they want until they become confident and are able to respond properly.`
  },
  {
      img : `${process.env.REACT_APP_ASSETS_URL}/images/home_computer.png`,
      title:'Real past interview questions',
      subText:`Students practice real questions that companies have asked in the past interviews.`
  },
  {
      img : `${process.env.REACT_APP_ASSETS_URL}/images/home_feedback.png`,
      title:`Objective feedback & tracking`,
      subText:`Get detailed objective reports on student's performance, and track how they have improved over time.`
  }
]

function Home() {
  const elementToScrollRef = React.useRef(null);
  const location = useLocation();

  const showDemo = React.useCallback(() => {
    if (elementToScrollRef.current) {
      elementToScrollRef.current.scrollIntoView({ behavior: "smooth",block: "center", inline: "nearest" });
    }
  }, []);

  React.useEffect(() => {
    if (location.hash === "#demo") showDemo();
  }, []);

  return (
    <AppShell>
      <Main onExperienceLive={showDemo}/>
      <ClientsSection/>
      <Problems/>
      <SolutionSection items={items}/>
      <Demo elementToScrollRef={elementToScrollRef} />
      <WithLanguify/>
      <Automate/>
      <BackedBy />
      <Faq />
    </AppShell>
  );
}

export default Home;
