import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

import VideoPlayer from "components/VideoPlayer";
import {animation} from "../../utils/index"

const useStyles = makeStyles((theme) => ({
    marginBottom:{
      marginBottom:theme.spacing(10)
    },
    paddingBottom:{
      paddingBottom:theme.spacing(5)
    },
    productVision:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center",
      width:"100%",
      position:"relative",
      marginTop:theme.spacing(10),
    },
    container:{
        background:`url("https://assets.languify.in/images/Snow.png")`,
        width:"100%",
        borderBottom:`2px solid ${theme.palette.divider}`,
    },
    box:{
        width:"100%",
        textAlign:"center",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        gap:theme.spacing(8),
        padding:theme.spacing(10,37.5),
        [theme.breakpoints.down('lg')]: {
            gap:theme.spacing(8),
            padding:theme.spacing(10,15),
        },
        [theme.breakpoints.down('md')]: {
            gap:theme.spacing(6),
            padding:theme.spacing(12,10)
        },
        [theme.breakpoints.down('md')]: {
          gap:theme.spacing(6),
          padding:theme.spacing(12,5)
      },
    },
    productVisionImgDimension:{
        height:"112px",
        width:"65px",
        [theme.breakpoints.down('lg')]: {
          height:"90px",
          width:"45px", 
        },
        [theme.breakpoints.down('md')]: {
          height:"56px",
          width:"30px", 
        }
    },
    productVisionImgLeft:{
      position:"absolute",
      left:35,
      top:0,
      opacity:0,
      transform:"translateX(-50px)",
      transition:"all 500ms ease",
      [theme.breakpoints.down('800')]: {
        left:"5dvw",
      }
    },
    productVisionImgRight:{
      position:"absolute",
      right:35,
      opacity:0,
      transform:"translateX(100%)",
      transition:"all 500ms ease",
      bottom: 20,
      [theme.breakpoints.down('800')]: {
        right:"5dvw",
        bottom:0
      }
    },
    productVisionVideo:{
      display:"flex",
      justifyContent:"center",
      width:"110%" 
    },
    tranfromFromBottom:{
      opacity:0,
      transform:"translateY(50px)",
      transition:"all 500ms ease",
      transitionDelay: "300ms"
    },
    enterFromX:{
      opacity:1,
      transform:"translateX(0px)",
    },
    enterFromY:{
      opacity:1,
      transform:"translateY(0px)",
    },
    text:{
        textAlign:"center",
        zIndex:4,
        [theme.breakpoints.down('lg')]: {
            width: '80%'
        },
        [theme.breakpoints.down('md')]: {
            width: '70%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
    }
  }));

export function ProductVision() {
    const classes=useStyles();

    React.useEffect(()=>{
        const handleScroll = () => {
            animation(`.${classes.productVisionImgLeft}`, `${classes.enterFromX}`);
            animation(`.${classes.productVisionImgRight}`, `${classes.enterFromX}`);
            animation(`.${classes.tranfromFromBottom}`, `${classes.enterFromY}`,20);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      },[])

    return (
        <Box className={classes.container}>
          <Box className={`${classes.productVision } ${classes.paddingBottom} ${classes.marginBottom}`}>
            <img 
              className={`${classes.productVisionImgLeft} ${classes.productVisionImgDimension}`}
              src={`${process.env.REACT_APP_ASSETS_URL}/images/t&t_hexa_left.svg`} 
              alt="img"
            />
          <Box className={classes.box}>
            <Typography 
              className={`${classes.tranfromFromBottom} ${classes.text}`} 
              variant="h4-medium" 
              color='primary.clr-600'
            >
              See how our product’s vision aligns
              with Edtech Mastermind Alakh Pandey
            </Typography>
            <Box className={`${classes.tranfromFromBottom} ${classes.productVisionVideo}`}>
              <VideoPlayer 
                link={"https://assets.languify.in/video/alakh_pandey.mp4"} 
                thumbnail={"https://assets.languify.in/images/alakh_pandey_thumbnail.webp"}
              />
            </Box>
          </Box>
          <img 
            className={`${classes.productVisionImgRight} ${classes.productVisionImgDimension}`} 
            src={`${process.env.REACT_APP_ASSETS_URL}/images/t&t_hexa_right.svg`} 
            alt="img"
          />
        </Box>
      </Box>
    );
}