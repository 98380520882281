import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { LoadingProvider } from "contexts/LoadingProvider";
import { SnackbarProvider } from "contexts/SnackbarProvider";

import Error from "./Error";
import NotFound from "./NotFound";
import Home from "./Home";
import AboutUs from "./AboutUs";
import ScrollToTop from "components/ScrollToTop";
import theme from "theme";
import Workspace from "./Workspace";
import SessionHelper from "components/SessionHelper";
import TeachAndTrain from "./TeachAndTrain";
import InPrep from "./InPrep";
import HeadWrapper from "components/Head";
import UseCase from "./UseCase";
// import Blog from "./Blog";
import Pricing from "./Pricing";
import B2C from "./B2C";
import ErrorBoundary from "components/ErrorBoundary";

export default function Screens() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <SnackbarProvider>
            <LoadingProvider>
              <ScrollToTop>
                <SessionHelper>
                  <Routes>
                    <Route path="/error" element={<Error />} />
                    <Route path="/aboutus" element={<HeadWrapper Component={AboutUs} />} />
                    <Route path="/teach&train" element={<HeadWrapper Component={TeachAndTrain} />} />
                    <Route path="/inprep" element={<HeadWrapper Component={InPrep} />} />
                    <Route path="/" element={<HeadWrapper Component={Home} />} />
                    <Route path="/workspace/" element={<Workspace />} />
                    <Route path="/pricing" element={<HeadWrapper Component={Pricing} />} />
                    <Route path="/usecase/*" element={<HeadWrapper Component={UseCase} />} />

                    {/* <Route path="/resources/*" element={<Blog />} /> */}
                    {/* <Route path="/b2c" element={<HeadWrapper Component={B2C} />} /> */}
                    <Route path="/*" element={<NotFound />} />
                  </Routes>
                </SessionHelper>
              </ScrollToTop>
            </LoadingProvider>
          </SnackbarProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  );
}
