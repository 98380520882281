import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    overflow: "hidden", 
    position: "relative",
  },
  carousel: {
    display: "flex", 
    alignItems: "center",
    gap: theme.spacing(13),
    [theme.breakpoints.down("md")]: {
      gap: "60px", 
    },
    [theme.breakpoints.down("sm")]: {
      gap: "30px", 
    },
  },
  clientImage: {
    flexShrink: 0,
    width: "140px", 
    height: "100px",
    objectFit: "contain",
  },
  scrollLeft: {
    animation: "$scrollLeft 120s linear infinite",
    [theme.breakpoints.down("md")]: {
      animation: "$scrollLeft 15s linear infinite",
    },
    [theme.breakpoints.down("sm")]: {
      animation: "$scrollLeft 10s linear infinite",
    },
  },
  scrollRight: {
    animation: "$scrollRight 120s linear infinite",
    [theme.breakpoints.down("md")]: {
      animation: "$scrollRight 15s linear infinite",
    },
    [theme.breakpoints.down("sm")]: {
      animation: "$scrollRight 10s linear infinite",
    },
  },
  "@keyframes scrollLeft": {
    "0%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(-400%)",
    },
  },
  "@keyframes scrollRight": {
    "0%": {
      transform: "translateX(-200%)",
    },
    "100%": {
      transform: "translateX(100%)",
    },
  },
}));

const ClientCarousel = ({ images, direction = 'left' }) => {
  const classes = useStyles();

  const duplicatedImages = [...images, ...images, ...images];

  return (
    <Box className={classes.container}>
      <Box className={`${classes.carousel} ${direction === 'left' ? classes.scrollLeft : classes.scrollRight}`}>
        {duplicatedImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`client-${index}`}
            className={classes.clientImage}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ClientCarousel;