import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import HeroSection from "components/HeroSection";

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: "calc(100vh - 20px)", height: "fit-content", display: "flex",
        flexDirection: 'column', justifyContent: "flex-start", width: "100%",
        position: "relative",
        [theme.breakpoints.up("md")]: {

        },
        [theme.breakpoints.down("md")]: {
            justifyContent: "space-evenly", minHeight: "fit-content",
        },
    },
    img: {
        width: 120,
        [theme.breakpoints.down("md")]: {
            width: 80, margin: '12px 0px'
        }
    },
    title: {
        fontWeight: 700, width: '100%', fontSize: '38px',
        lineHeight: '38px', display: 'flex',
        [theme.breakpoints.down("md")]: {
            fontSize: '32px', textAlign: 'left', lineHeight: '38px',
        },

    },
    subTitle: {
        fontSize: '1.2vw', fontWeight: 600,
        [theme.breakpoints.down("md")]: {
            fontSize: 16, textAlign: 'left',
        }
    }, mainContainer: {
        width: '100%', height: '100%', display: 'flex',
        [theme.breakpoints.down("md")]: {
            height: '100%', alignItems: 'center', flexDirection: 'column'
        },
    },
    contentContainer: {
        width: '45%', marginTop: 60, paddingLeft: '64px', height: '60%',
        display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly',
        [theme.breakpoints.down("md")]: {
            width: '100%', padding: 20, display: 'flex', alignItems: 'center',
            flexDirection: 'column', marginTop: 0,
        }
    },
    imgContainer: {
        width: '55%', textAlign: 'center', paddingTop: 40,
        [theme.breakpoints.down("md")]: {
            width: '90%',
        }
    },
    primaryBtn: {
        height: 50, fontSize: 18,
        [theme.breakpoints.down("md")]: { fontSize: 12, height: 'fit-content', }
    },
    secondaryBtn: {
        height: 50, fontSize: 18,
        marginTop:'-0.055rem',
        [theme.breakpoints.down("md")]: { fontSize: 12, height: 'fit-content',marginTop:'1px', }
    },
    doddleContainer: {
        position: 'relative',
    },
    doddle: {
        position: 'absolute', left: -130, bottom: -58, width: 400,
        [theme.breakpoints.down("md")]: { width: 260, bottom: -30, left: -120 }
    },
    actions: {
        display: 'flex', gap: 14, margin: '40px 0px', alignItems: 'center',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column', alignItems: 'center'
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row', alignItems: 'center'
        }
    },
    spanHead: {
        marginRight: theme.spacing(3),
        background: 'linear-gradient(90.71deg, #8270DB 39.46%, #003E8C 99.73%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
    },
    typography:{
        fontFamily:theme.typography['h1-medium'].fontFamily,
        fontSize: "57px",
        fontWeight: 500,
        lineHeight: "68.4px",
        textAlign: "left",
        [theme.breakpoints.down("md")]: {
           fontSize: "48px",
           lineHeight: "59.52px",
          }
    }
}));

export default function Main({ onExperienceLive }) {
    const classes = useStyles();

    const title = (
        <Box>
            <Typography className={classes.typography} >
                We help your students to move from 
                <span  className={classes.spanHead}>
                    &nbsp;Classroom to Career
                </span>
            </Typography>
        </Box>
    );

    return (
        <HeroSection 
            title={title} 
            img={[`${process.env.REACT_APP_ASSETS_URL}/images/home_main_1.png`,
                `${process.env.REACT_APP_ASSETS_URL}/images/home_main_2.png`]}
            onExperienceLive={onExperienceLive}
        />
    );
}