import React from 'react'
import Education from './Education'
import InterviewPrep from './InterviewPrep'
import NotFound from 'screens/NotFound'

import { Route, Routes } from 'react-router-dom'

const UseCase = () => {
  return (
    <Routes>
      <Route path='/' element={<NotFound />} />
      <Route path='/education' element={<Education />} />
      <Route path='/interview-preparation' element={<InterviewPrep />} />
    </Routes>
  )
}

export default UseCase
