import React from 'react'

import { clients, productBased, serviceBased } from 'components/B2C/Constant';
import B2cAppShell from 'components/B2cAppShell';
import B2cHeroSection from 'components/B2C/B2cHeroSection';
import B2cCompanyTags from 'components/B2C/B2cCompanyTags';
import StudentTestimonial from 'components/B2C/StudentTestimonial';
import UseCase from 'components/B2C/UseCase';
import B2cMockOverview from 'components/B2C/B2cMockOverview';
import B2cFeedbackOverview from 'components/B2C/B2cFeedbackOverview';
import PrepareInterview from 'components/B2C/PrepareInterview';
import B2cPrice from 'components/B2C/B2cPrice';
import B2cConfusedSection from 'components/B2C/B2cConfusedSection';
import Faq from 'components/B2C/B2cFaq';

const B2C = () => {

  return (
    <B2cAppShell>
       <B2cHeroSection />
       <B2cCompanyTags topItems={productBased} botItems={serviceBased} />
       <StudentTestimonial />
       <B2cCompanyTags students={true} topItems={clients}/>
       <UseCase/>
       <B2cMockOverview /> 
       <B2cFeedbackOverview />
       <PrepareInterview />
       <B2cPrice />
       <B2cConfusedSection />
       <Faq />
    </B2cAppShell>
  )
}

export default B2C
