import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(10),
        overflow: 'hidden', 
        position: 'relative',
        width: '100%',
        minHeight:'550px',
        height:"auto",
        [theme.breakpoints.down("lg")]: {
            height:'580px',
        },
        [theme.breakpoints.down("700")]: {
            height:'600px',
        },
        [theme.breakpoints.down("650")]: {
            height:'650px',
        },
        [theme.breakpoints.down("420")]: {
            minHeight:'690px',
        },
        [theme.breakpoints.down("380")]: {
            minHeight:'720px',
        }
    },
    instituteCard: {
        width: '946px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: theme.spacing(3),
        border: '2px solid',
        gap:theme.spacing(1),
        borderColor: theme.palette.neutral['clr-300'],
        padding: theme.spacing(0,5,5,5),
        boxShadow: '0px 2px 10px 2px #003E8C4D',
        position: 'absolute',
        top: '5px',
        transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
        opacity: 0,
        transform: 'translateX(100%)',
        '&.active': {
            transform: 'translateX(0)',
            opacity: 1,
        },
        '&.inactive-left': {
            transform: 'translateX(-100%)',
            opacity: 0,
        },
        '&.inactive-right': {
            transform: 'translateX(100%)',
            opacity: 0,
        },
        [theme.breakpoints.down("lg")]: {
            width: '100%',
            padding:theme.spacing(0,0,4,0),
        },
        [theme.breakpoints.down("md")]: {
            padding:theme.spacing(0,1,9.5,1),
            boxShadow:'none',
            gap:theme.spacing(1),
        },
        [theme.breakpoints.down("sm")]: {
            padding:theme.spacing(0,1,6,1),
            boxShadow:'none',
            gap:theme.spacing(1),
        },
        [theme.breakpoints.down("350")]: {
            padding:theme.spacing(0,1,3,1),
            boxShadow:'none',
            gap:theme.spacing(1),
        },
    },
    topContents: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    midContents: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(4),
    },
    textContent: {
        padding: theme.spacing(0, 6),
        [theme.breakpoints.down("md")]: {
           padding: theme.spacing(0, 2.5)
             }
    },
    spanText: {
        ...theme.typography['h5-medium'],
        color: theme.palette.primary.main,
        [theme.breakpoints.down("md")]: {
            ...theme.typography['h6-medium'],
             }
    },
    spanImage:{
        [theme.breakpoints.down("md")]: {
            width:'20px',
             }
    },
    testimonialCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(3),
        [theme.breakpoints.down("630")]: {
            flexDirection: 'row',
            padding: theme.spacing(0, 2.5),
            width: '100%',
            justifyContent:'space-between'
             },
        [theme.breakpoints.down("600")]: {
            flexDirection: 'row',
            padding: theme.spacing(0, 2.5),
            width: '100%',
            justifyContent:'flex-start'
             }
    },
    midText: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(1),
        [theme.breakpoints.down("600")]: {
           alignItems: 'flex-start',
             }
    },
    bottomContents: {
        marginTop: theme.spacing(6),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 8),
        width:"100%",
        [theme.breakpoints.down("800")]: {
            padding: theme.spacing(0, 2.5),
         },
        [theme.breakpoints.down("630")]: {
           flexWrap:'wrap',
           gap: theme.spacing(5),
           width:"100%",
           margin:"auto",
           marginTop:0,
           marginBottom:0,
        },
        [theme.breakpoints.down("sm")]: {
            justifyContent:"space-around",
            marginTop:theme.spacing(4),
            padding: theme.spacing(0, 3),
            width:"100%",
        },
    },
    statsBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        [theme.breakpoints.down("lg")]: {
            width:'31%',
              },
        [theme.breakpoints.down("md")]: {
            width:'180px',
              },
        [theme.breakpoints.down("660")]: {
            width:'160px',
        },
        [theme.breakpoints.down("630")]: {
            width:'100%',
            }
    },
    scrollButtonBox: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(3),
        position:'relative',
        top: '248px',
        [theme.breakpoints.down("700")]: {
            top: '278px',
        },
        [theme.breakpoints.down("600")]: {
            top: '300px',
        },
        [theme.breakpoints.down("550")]: {
            top: '310px',
        },
        [theme.breakpoints.down("420")]: {
            top: '320px',
        },
        [theme.breakpoints.down("380")]: {
            top: '350px',
        }
    },
    scrollButtonActive: {
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        background: theme.palette.primary.main,
        cursor: 'pointer',
    },
    scrollButton: {
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        background: '#CCD4DE',
        cursor: 'pointer',
    },
    imageDot:{
        [theme.breakpoints.down("md")]: {
          display:'none',
           }
    },
    imageDotMob:{
        display:'none',
        [theme.breakpoints.down("md")]: {
            display:'block',
             
    }
},
    imageLogo:{
        [theme.breakpoints.down("md")]: {
            width:'140px',
             }
    }
}));

const items = [
    {
        img: `${process.env.REACT_APP_ASSETS_URL}/images/testimonial_upGrad.svg`,
        testimonial: `It is a fantastic product. Languify has built a strong tech and is 
        continuously improving. The positive thing, the product can be used for multiple scenarios. 
        This solves a big hurdle for us at upGrad campus.`,
        name: 'Deepak Parmar',
        personImage: `${process.env.REACT_APP_ASSETS_URL}/images/testimonial_Deepak.svg`,
        position: 'Associate Director Training at upGrad campus',
        stats: [
            {
                percentage: '42%',
                text: 'Average increase in communication & core-concept for 1500 upGrade student'
            },
            {
                percentage: '187+ Hrs',
                text: 'Saving on operational cost by integrating our AI for learning'
            },
            {
                percentage: '50%',
                text: 'Saving on operational cost by integrating our AI for learning'
            }
        ]
    },
    {
        img: `${process.env.REACT_APP_ASSETS_URL}/images/testimonial_cipher.svg`,
        testimonial: `Very impressed with the way languify is building. We had a good response with 
        the pilot integration & lots of things to ponder. Now that we explore a possibility for a 
        long term collaboration. Feedback generated by Languify AI has quality & continuously 
        improving.`,
        name: 'Anurag Mishra',
        personImage:  `${process.env.REACT_APP_ASSETS_URL}/images/testimonial_Anurag.svg`,
        position: 'Founder of Cipher Schools',
        stats: [
            {
                percentage: '37.04%',
                text: `Average increase in communication & core-concept for 120 students over 700+ 
                assessments`
            },
            {
                percentage: '86%',
                text: `Average completion rate for all the assessments by integrating our AI for 
                learning`
            },
            {
                percentage: '50%',
                text: 'Saving on operational cost by integrating our AI for learning'
            }
        ]
    },
    {
        img: `${process.env.REACT_APP_ASSETS_URL}/images/testimonial_functionUp.svg`,
        testimonial: `They have wonderful team in place. For us, they solve a major hurdle which is 
        to assess the learning engagement in-class for students. Languify has kept the pricing 
        extremely affordable. Looking forward to a long term association with Languify.`,
        name: 'Bharat Gupta',
        personImage: `${process.env.REACT_APP_ASSETS_URL}/images/testimonial_Bharat.svg`,
        position: 'CEO & Co-founder at FuncionUp',
        stats: [
            {
                percentage: '47.7%',
                text: `Average increase in communication & core-concept for 250 students over 1500+ 
                assessments`
            },
            {
                percentage: '187+ Hrs',
                text: 'Saving on operational time by integrating our AI for learning'
            },
            {
                percentage: '50%',
                text: 'Saving on operational cost by integrating our AI for learning'
            }
        ]
    }
];

const InstituteCard = () => {
    const classes = useStyles();
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            // Automatically move to the next card every 5 seconds
            setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
        }, 5000);

        return () => clearTimeout(timer);
    }, [activeIndex]);

    const handleScrollButtonClick = (index) => {
        setActiveIndex(index);
    };

    return (
        <Box className={classes.mainContainer}>
            {items.map((item, index) => (
                <Box
                    className={`${classes.instituteCard} 
                    ${index === activeIndex ? 'active' : index < activeIndex ? 
                    'inactive-left' : 'inactive-right'}`}
                    key={index}
                >
                    <Box className={classes.topContents}>
                        <img 
                          src={`${process.env.REACT_APP_ASSETS_URL}/images/testimonial_dotted_frame.svg`}
                          alt='img' 
                          className={classes.imageDot}
                        />
                        <img 
                          src={`${process.env.REACT_APP_ASSETS_URL}/images/testimonial_dotted_mob.png`}
                          alt='dots' 
                          className={classes.imageDotMob}                            
                        />
                        <img 
                          src={item.img} 
                          alt={item.name} 
                          className={classes.imageLogo}
                        />
                        <img 
                          src={`${process.env.REACT_APP_ASSETS_URL}/images/testimonial_dotted_frame.svg`}
                          alt='img'
                          className={classes.imageDot} 
                        />
                        <img 
                          src={`${process.env.REACT_APP_ASSETS_URL}/images/testimonial_dotted_mob.png`}
                          alt='dots'
                          className={classes.imageDotMob} 
                        />
                    </Box>
                    <Box className={classes.midContents}>
                        <Box className={classes.textContent}>
                            <span>
                                <img 
                                    src={`${process.env.REACT_APP_ASSETS_URL}/images/testimonial_left_quotation.png`}
                                    alt='left' 
                                    className={classes.spanImage}
                                />
                            </span>
                            <span className={classes.spanText}>
                                {item.testimonial}
                            </span>
                            <span>
                                <img 
                                    src={`${process.env.REACT_APP_ASSETS_URL}/images/testimonial_right_quatation.png`}
                                    alt='right' 
                                    className={classes.spanImage}
                                />                                                                           
                            </span>
                        </Box>
                        <Box className={classes.testimonialCard}>
                            <img src={item.personImage} width={52} alt={item.name} />
                            <Box className={classes.midText}>
                                <Typography variant='body01-bold'>{item.name}</Typography>
                                <Typography variant='body01-medium'>{item.position}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.bottomContents}>
                        {item.stats.map((stat, statIndex) => (
                            <Box className={classes.statsBox} key={statIndex}>
                                <Typography variant='h3-semiBold'>
                                    {stat.percentage}
                                </Typography>
                                <Typography variant='body01-bold' color='neutral.clr-500'>
                                    {stat.text}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            ))}
            <Box className={classes.scrollButtonBox}>
                {items.map((_, index) => (
                    <Box
                        key={index}
                        className={index === activeIndex ? 
                        classes.scrollButtonActive : classes.scrollButton}
                        onClick={() => handleScrollButtonClick(index)}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default InstituteCard;
