import { Box } from '@mui/material'
import React from 'react'
import B2cHeader from './B2C/B2cHeader'
import B2cFooter from './B2C/B2cFooter'

const B2cAppShell = ({children})=> {
  return (
    <Box
      overflow="hidden"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
    >
     <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      minHeight="100vh"
    >
      <B2cHeader />
      {children}
    </Box>
    <B2cFooter />
  </Box>
  )
}

export default B2cAppShell