import Client from "./_client";

export const sendFeedbackMail = async (feedbackUrl, email) => {

    const result = await new Client({
        path: `/user/send-demo-feedback-link`,
        payload: {
            feedbackUrl, email
        },
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! unable to send mail, Please try again"
        );
    }
    return result?.data;
};

export const addB2cFreeTrialUser = async (userData) => {

    const result = await new Client({
        path: `/user/b2c/free-trial`,
        payload: userData,
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! unable to add user, Please try again"
        );
    }
    return result?.data;
};
