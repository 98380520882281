import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  "img":{
    width:1500,
    objectFit:"cover",
    objectPosition:"center",
    [theme.breakpoints.down('sm')]:{
      width:850,
    },
    [theme.breakpoints.down('450')]:{
      width:800,
    }
  },
  wave1:{
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    bottom:-4,
    width:1500,
    objectFit:"cover",
    left:0,
    animationDuration:"8s",
    [theme.breakpoints.down('sm')]:{
      width:800,
    },
    '&:nth-of-type(1)': {
      animation: '$wave1 8s linear infinite',
      zIndex: 10,
      height:50,
      [theme.breakpoints.down('sm')]:{
        animation: '$wave1 8s linear infinite',
      },
    },
    '&:nth-of-type(2)': {
      animation: '$wave1 8s linear  infinite',
      animationDelay:"-4s",
      zIndex: 9,
      height:90,
      [theme.breakpoints.down('sm')]:{
        animation: '$wave1 8s linear  infinite',
        animationDelay:"-4s",
      },
    },
    '&:nth-of-type(3)': {
      animation: '$wave1 8s linear infinite',
      zIndex: 8,
      height:120,
      [theme.breakpoints.down('sm')]:{
        animation: '$wave1 8s linear infinite',
      },
    },
    '&:nth-of-type(4)': {
      animation: '$wave1 8s linear  infinite',
      animationDelay:"-4s",
      zIndex: 7,
      height:150,
      [theme.breakpoints.down('sm')]:{
        animation: '$wave1 8s linear  infinite',
        animationDelay:"-4s",
      },
    },
    '&:nth-of-type(5)': {
      animation: '$wave1 8s linear infinite',
      zIndex: 6,
      height:180,
      backgroundSize:"cover",
      [theme.breakpoints.down('sm')]:{
        animation: '$wave1 8s linear infinite',
      },
    },
    '&:nth-of-type(6)': {
      animation: '$wave1 8s linear  infinite',
      animationDelay:"-4s",
      zIndex: 5,
      height:220,
      [theme.breakpoints.down('sm')]:{
        animation: '$wave1 8s linear  infinite',
        animationDelay:"-4s",
      },
    },
  },
  container:{
    maxWidth:"948px",
    overflow:"hidden",
    position:"absolute",
    bottom:0,
    height:"220px",
    width:"100%",
    left:0,
    [theme.breakpoints.down('sm')]:{
      maxWidth:800
    }
  },
  waveBox:{
    width:"100%",
    overflow:"hidden",
    position:"relative",
    height:"100%", 
  },
  '@keyframes wave': {
    '0%': {
      transform: "translate3d(-100px, 0, 0)",
    },
    '50%': {
      transform: "translate3d(0px, 0, 0)",
    },
    '100%': {
      transform: "translate3d(-100px, 0, 0)",
    },
  },
  '@keyframes wave1': {
    '0%': {
        transform: "translate3d(-250px, 0, 0)",
    },
    '50%': {
      transform: "translate3d(0px, 0, 0)",
    },
    '100%': {
        transform: "translate3d(-250px, 0, 0)",
    },
    
  },
}));


export default function WaveEffect({
  isActive = true
}) {
  const classes = useStyles();

  return (
      <>
        <Box className={classes.container}>
          <Box className={classes.waveBox}>
            <Box 
              style={{ animationPlayState: isActive ? 'running' : 'paused' }} 
              className={classes.wave1}>
                <img 
                  className={classes.img} 
                  src={`${process.env.REACT_APP_ASSETS_URL}/images/ai_wave1.svg`} 
                  alt="wave1 img"/>
            </Box>
            <Box 
              style={{ animationPlayState: isActive ? 'running' : 'paused' }} 
              className={classes.wave1}>
                <img 
                  src={`${process.env.REACT_APP_ASSETS_URL}/images/ai_wave2.svg`} 
                  className={classes.img} 
                  alt="wave2 img"/>
            </Box>
            <Box 
              style={{ animationPlayState: isActive ? 'running' : 'paused' }} 
              className={classes.wave1}>
                <img 
                  src={`${process.env.REACT_APP_ASSETS_URL}/images/ai_wave3.svg`} 
                  className={classes.img} 
                  alt="wave3 img"/>
            </Box>
            <Box 
              style={{ animationPlayState: isActive ? 'running' : 'paused' }} 
              className={classes.wave1}>
                <img 
                  src={`${process.env.REACT_APP_ASSETS_URL}/images/ai_wave4.svg`} 
                  className={classes.img} 
                  alt="wave4 img"/>
            </Box>
            <Box 
              style={{ animationPlayState: isActive ? 'running' : 'paused' }} 
              className={classes.wave1}>
                <img 
                  src={`${process.env.REACT_APP_ASSETS_URL}/images/ai_wave5.svg`} 
                  className={classes.img} 
                  alt="wave5 img"/>
            </Box>
            <Box 
              style={{ animationPlayState: isActive ? 'running' : 'paused' }} 
              className={classes.wave1}>
                <img 
                  src={`${process.env.REACT_APP_ASSETS_URL}/images/ai_wave6.svg`} 
                  className={classes.img} 
                  alt="wave6 img"/>
            </Box>
          </Box>
        </Box>
      </>
  )
}