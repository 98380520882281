import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles';
import MuiSelect from '@mui/material/Select';

import { useTheme } from '@mui/material/styles';
import { Box, MenuItem, FormControl, Typography, Skeleton } from '@mui/material';

const useStyles = makeStyles(theme => ({
  label: {
    ...theme.typography['h3-medium'],
    marginBottom: theme.spacing(3),
    color: theme.palette.shades['clr-white-900']
  },
  ".MuiFormControl-root": {
    width: "100%"
  },
  ".MuiSelect-nativeInput": {
    height: 48,
  },
  ".MuiSelect-root": {
    ...theme.typography['h6-medium'],
    display: "flex",
    height: "48px",
    maxHeight: 48,
    alignItems: "center",
    border: '2px solid',
    width: "100%",
    borderColor: theme.palette.shades['clr-white-900'],
    backgroundColor: theme.palette.shades['clr-offWhite'],
    "& .MuiInputLabel-root": {
      color: "green"
    },
    '&:hover': {
      backgroundColor: theme.palette.neutral['clr-50'],
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.neutral['clr-200'],
      '& fieldset': {
        border: '2px solid',
      },
    },
  },
  ".MuiSelect-select": {
    "& #select": {
      display: "flex",
      alignItems: "center"
    },
    "& fieldset": {
      border: 'none',
    },
    "&:hover": {
      "& fieldset": {
        border: 'none',
      },
    },
  },
  groupList: {
    maxHeight: 300,
    overflowY: "auto",
    padding: '0px',
    "& li": {
      ...theme.typography['h6-regular'],
      color: theme.palette.neutral['clr-800'],
      height: '48px',
      position: "relative",
    },
    "& li:hover": {
      backgroundColor: theme.palette.primary['clr-50'],
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-disabled': {
        backgroundColor: theme.palette.neutral['clr-200'],
      },
    },
    "& li.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.shades['clr-white-900'],
    },
    "& li.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.shades['clr-white-900'],
    },
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.neutral['clr-200']}`,
    position: "absolute",
    bottom: "0",
    left: "-2.5%",
    width: "102.5%"
  }
}));

export function SimpleSelect({
  options,
  defaultValue,
  value,
  onSelect = () => { },
  label = null,
  placeholder = 'Select option',
  loading = false,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();

  const menuProps = React.useMemo(() => ({
    classes: {
      paper: classes.paper,
      list: classes.groupList,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  }), [classes.list, classes.paper]);

  const iconComponent = (props) => (
    <ExpandMoreIcon
      className={`${props.className} ${classes.icon}`}
      style={{ color: theme.palette.primary.main }} />
  );

  return (
    <FormControl className={classes['.MuiFormControl-root']}>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <>
        {loading ? (
          <SelectSkeleton />
        ) : (
          <MuiSelect
            displayEmpty
            className={`${classes['.MuiSelect-nativeInput']} ${classes['.MuiSelect-root']} 
            ${classes['.MuiSelect-select']}`}
            sx={{ minWidth: 200, ...(props.sx ? props.sx : {}) }}
            onChange={onSelect}
            renderValue={(selected) => {
              const data = options.find((option) => option._id === selected);
              if (!selected) {
                return placeholder;
              } else {
                return (
                  <span style={{ color: theme.palette.primary.main }}>
                    {data?.name}
                  </span>
                );
              }
            }}
            value={value}
            inputProps={{ className: classes.input }}
            MenuProps={menuProps}
            IconComponent={iconComponent}
            {...props}
            id="select"
          >
            {options?.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {option.name}
                <Box className={classes.borderBottom}></Box>
              </MenuItem>

            ))}

          </MuiSelect>
        )}
      </>
    </FormControl>
  );
}
const SelectSkeleton = () => (
  <Box>
    <Skeleton variant="rectangular" width="200px" height="44px" style={{ borderRadius: '4px' }} />
  </Box>
);