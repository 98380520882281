import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme=>({
  content: {
    height: '95vh', padding: '0px', overflow: 'hidden'
  },
  closeBtn: {
    position: 'absolute',
    right: 30,
    top: 20,
    color: theme.palette.neutral['clr-700'],
    backgroundColor: theme.palette.neutral['clr-100'],
    '&:hover': {
      backgroundColor: theme.palette.neutral['clr-200'],
    }
  },
  head: {
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
  },
  header: {
    padding: theme.spacing(6, 5),
    display: 'flex',
    gap: theme.spacing(3)
  },
  actionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    marginRight: '30px'
  }, 
  socialLinks: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));


export default function FeedbackDialog({ 
  openDialog, setOpenDialog, url, onSubmit 
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(openDialog);
  
  const handleClose = () => {
    setOpenDialog(false);
  };

  React.useEffect(()=>{
    setOpen(openDialog);
  },[openDialog]);

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={'xl'}
      fullWidth={true}
      className={classes.root}
      open={open}
    >
      <Box className={classes.head}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={classes.closeBtn}
        >
          <CloseIcon style={{ fontSize: '30px'}}/>
        </IconButton>
      </Box>
      {/* content */}
      <DialogContent className={classes.content}>
          <iframe
            title="Feedback"
            src={url}
            style={{ width: "100%", height: '100%' }}
          />
      </DialogContent>
    </Dialog>
  );
}