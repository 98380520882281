import React from "react";
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box';
import MUITextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

import { useTheme } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    display: 'block',
    borderRadius: theme.spacing(1),
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: theme.palette.neutral['clr-600']
    },
    '& .MuiOutlinedInput-input': {
      fontSize: '16px',
      color: `${theme.palette.neutral['clr-800']} !important`,
      height: 'fit-content',
      ...theme.typography['body01-medium'],
      padding: theme.spacing(3, 5),
    },
    '& .MuiOutlinedInput-root': {
      '&:hover': {
        backgroundColor: theme.palette.neutral['clr-50']
      },
      height: '44px !important',
      borderRadius: theme.spacing(1),
      fontSize: 16,
      '& fieldset': {
        borderColor: theme.palette.neutral['clr-500'],
      },
      '&:hover fieldset': {
        borderColor: theme.palette.neutral['clr-500'],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary['clr-300'],
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.neutral['clr-200'],
        '& fieldset': {
          border: '2px solid',
          borderColor: theme.palette.neutral['clr-400'],
        },
      }
    },
  },
  textArea: {
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing(0),
    },
    '& .MuiOutlinedInput-root': {
      height: 'min-content !important'
    }
  },
  searchField: {
    '& .MuiOutlinedInput-input': {
      paddingLeft: '0px',
      paddingRight: theme.spacing(5)
    },
    '& .MuiOutlinedInput-root': {
      padding: '0px'
    },
    '& .MuiInputAdornment-root': {
      padding: '0px', margin: '0px'
    }
  },
  autoCompleteField: {
    '& .MuiOutlinedInput-input': {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(5)
    },
    '& .MuiOutlinedInput-root': {
      padding: '0px'
    },
    '& .MuiInputAdornment-root': {
      padding: '0px', margin: '0px'
    }
  },
  errorTxtField: {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-input': {
        color: theme.palette.danger.main
      },
      '& fieldset': {
        borderColor: theme.palette.danger.main,
      },
    },
  },
  successTxtField: {
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-input': {
        color: theme.palette.success.main
      },
      '& fieldset': {
        borderColor: theme.palette.success.main,
      },
    },
  },
  supportiveTxt: {
    display: 'block',
    marginTop: theme.spacing(1),
    ...theme.typography['body02-medium'],
    color: theme.palette.neutral['clr-400'],
  },
  label: {
    ...theme.typography['body01-bold'],
    textTransform: 'none', marginBottom: theme.spacing(2)
  },
}));

export default function CustomTextField(props) {

  return (
    <TextField
      size="small"
      variant="outlined"
      fullWidth
      sx={{
        color: "#9C9C9C",
        minWidth: 120,
        ...(props.sx ? { ...props.sx } : {})
      }}
      {...props}
    />
  );
}

export function TextFieldWithLabel({
  error = null, success = null, supportingText = null, label = null, required = false, supportingLabel = '',
  onChange = () => { }, value, helperText = '', ...props
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box>
      {
        label && <Box display='flex'>
          <Typography className={classes.label}>{label}</Typography>
          {required && <Typography variant='body01-bold' color='danger.clr-700'>*</Typography>}
          <Typography variant='body01-medium' color='neutral.clr-700'>{supportingLabel}</Typography>
        </Box>
      }
      <MUITextField
        size='small'
        className={
          `
            ${classes.textField} 
            ${error ? classes.errorTxtField : success ? classes.successTxtField : ''}
          `
        }
        variant="outlined"
        spellCheck="false"
        onChange={onChange}
        value={value}
        fullWidth
        style={{
          minWidth: 100,
          ...(props.sx ? { ...props.sx } : {}),
        }}
        InputProps={
          error ?
            {
              endAdornment: (
                <InputAdornment position="end">
                  <WarningAmberOutlinedIcon fontSize='small' style={{ color: theme.palette.danger.main }} />
                </InputAdornment>
              )
            }

            : success ?
              {
                endAdornment: (
                  <InputAdornment position="end">
                    <CheckCircleOutlinedIcon fontSize='small' style={{ color: theme.palette.success.main }} />
                  </InputAdornment>
                )
              } : null
        }
        {...props}
      />
      {
        error &&
        <Typography
          className={classes.supportiveTxt}
          style={{ color: theme.palette.danger.main }}
        >
          {helperText || error}
        </Typography>
      }
      {
        success &&
        <Typography
          className={classes.supportiveTxt}
          style={{ color: theme.palette.success.main }}
        >
          {success}
        </Typography>
      }
      {
        supportingText &&
        <Typography className={classes.supportiveTxt}>{supportingText}</Typography>
      }
    </Box>
  );
}