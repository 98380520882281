import theme from 'theme';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TitleTooltip from './TitleTooltip';
import Skeleton from '@mui/material/Skeleton';

export const useStyles = makeStyles(theme => ({
    label: {
        ...theme.typography['body01-bold'],
        textTransform: 'none', marginBottom: theme.spacing(2)
    },
    ".MuiSelect-nativeInput": {
        height: 40,
    },
    ".MuiSelect-root": {
        ...theme.typography['body01-medium'],
        height: '44px',
        '&:hover': {
            backgroundColor: theme.palette.neutral['clr-50']
        },
        '&.Mui-disabled': {
            backgroundColor: theme.palette.neutral['clr-200'],
            '& fieldset': {
                border: '2px solid',
            },
        }
    },
    ".MuiSelect-select": {
        "& fieldset": {
            border: '1px solid',
            borderColor: theme.palette.neutral['clr-500']
        },
        "&:hover": {
            "& fieldset": {
                border: '1px solid',
            },
        },
    },
    paper: {
        boxShadow: "0px 2px 8px 0px #00000029",
    },
    groupList: (props) => ({
        maxHeight: 300,
        overflowY: "auto",
        padding: '0px',
        zIndex: 5,
        "& li": {
            ...theme.typography['body01-medium'],
            height: '40px',
            borderBottom: `1px solid ${theme.palette.neutral['clr-200']}`,
            width: props.width || '100%',
            overflow: "hidden",
        },
        "& li:hover": {
            backgroundColor: theme.palette.primary['clr-50'],
            height: 'auto',
            minHeight: '40px'
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-disabled': {
                backgroundColor: theme.palette.neutral['clr-200'],
                borderColor: theme.palette.neutral['clr-200'],
                '& fieldset': {
                    border: '2px solid',
                    borderColor: theme.palette.primary['clr-600'],
                },
            }
        },
        "& li.Mui-selected": {
            backgroundColor: `${theme.palette.primary['clr-400']} !important`,
            color: theme.palette.shades['clr-white-900']
        },
        "& li.Mui-selected:hover": {
            backgroundColor: theme.palette.primary['clr-400'],
            color: theme.palette.shades['clr-white-900']
        }
    }),
    category: {
        backgroundColor: '#fcf8f8',
        height: 40, display: 'flex', alignItems: 'center',
        color: theme.palette.primary.main,
        fontWeight: '600',
        border: '2px solid',
        borderColor: theme.palette.neutral.main,
        fontSize: '16px !important',
        borderWidth: '2px 0px 2px 0px',
    },
    radioSelectContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(3),
        flexWrap: 'wrap'
    },
    radioSelectOption: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        border: (props) => (props.documentSelect ? `2px solid #CCD4DE` : ''),
        color: theme.palette.neutral['clr-600'],
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
            backgroundColor: (props) => (props.documentSelect ? '' : theme.palette.neutral['clr-50']),
        },
        '&.selected': {
            border: (props) => (props.documentSelect ? `2px solid ${theme.palette.primary.main}` : ''),
            color: theme.palette.primary['clr-300'],
            '&:hover $optionImage': {
                bottom: '-28px',
            },
        },
        '&:not(.selected)': {
            '&:hover': {
                boxShadow: (props) => (props.documentSelect ? '0px 2px 8px 0px #00000029' : ''),
            },
            '&:hover $optionImage': {
                bottom: '-12px',
                boxShadow: '0px 0px 8px 0px #1961BF4D'
            },
        },
    },
    radioSelectLabel: {
        ...theme.typography['body01-bold'],
        minWidth: (props) => (props.documentSelect ? '192px' : ''),
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.neutral['clr-600'],
        '&.selected': {
            color: theme.palette.primary['clr-300'],
        },
        position: 'relative'
    },
    radioLabelConatiner: {
        display: 'flex',
        flexDirection: 'row',
        width: (props) => (props.documentSelect ? '192px' : ''),
        paddingRight: (props) => (props.documentSelect ? '' : '10px'),
        paddingTop: (props) => (props.documentSelect ? '' : '10px'),
        paddingBottom: (props) => (props.documentSelect ? '' : '10px')
    },
    radioButton: {
        height: '100%',
        width: '48px',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: theme.palette.neutral['clr-50'],
        },
    },
    radioImage: {
        padding: theme.spacing(3.5, 2),
        borderBottom: `1px solid ${theme.palette.neutral['clr-200']}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    },
    optionImageBox: {
        position: 'absolute',
        maxHeight: '92px',
        overflowY: 'hidden',
        top: '12px',
        width: '96px',
        display: 'flex',
        justifyContent: 'center'
    },
    optionImage: {
        position: 'relative',
        bottom: '-28px',
        transition: 'bottom 0.5s ease',
    },
    textField: {
        display: 'block',
        borderRadius: theme.spacing(1),
        '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: theme.palette.neutral['clr-600']
        },
        '& .MuiOutlinedInput-input': {
            fontSize: '16px',
            color: `${theme.palette.neutral['clr-700']} !important`,
            height: 'fit-content',
            ...theme.typography['body01-medium'],
            padding: theme.spacing(3, 2),
            backgroundColor: theme.palette.shades['clr-white-900']
        },
        '& .MuiOutlinedInput-root': {
            '&:hover': {
                backgroundColor: theme.palette.shades['clr-white-900']
            },
            height: '36px !important',
            borderRadius: theme.spacing(1),
            fontSize: 16,
            '& fieldset': {
                borderColor: theme.palette.neutral['clr-200'],
            },
            '&:hover fieldset': {
                borderColor: theme.palette.neutral['clr-200'],
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary['clr-500'],
            },
            '&.Mui-disabled': {
                backgroundColor: theme.palette.neutral['clr-200'],
                '& fieldset': {
                    border: '2px solid',
                    borderColor: theme.palette.neutral['clr-400'],
                },
            }
        },
    },
    overflowText: {
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
    },
    truncatedTypography: {
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxHeight: '1.4em',
        ...theme.typography['body01-medium']
    },
    overflown: {
        '&:hover': {
            whiteSpace: 'normal',
            WebkitLineClamp: 2,
            maxHeight: '2.8em',
            ...theme.typography['body01-medium'],
            margin: theme.spacing(1, 0)
        },
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: theme.spacing(1)
    },
    supportiveTxt: {
        display: 'block',
        marginTop: theme.spacing(1),
        ...theme.typography['body02-medium'],
        color: theme.palette.neutral['clr-400'],
    },
}));


export function SimpleSelectWithLabel({
    options, defaultValue, value, onSelect = () => { }, label = null, placeholder = 'Select option',
    loading = false, required = false, helperText = '', ...props
}) {
    const classes = useStyles({ width: props?.menuStyle?.width });

    const menuProps = React.useMemo(() => ({
        classes: {
            paper: classes.paper,
            list: classes.groupList
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        MenuListProps: {
            style: {
                ...props.menuStyle
            }
        }
    }), []);

    const handleSelection = (e) => {
        const selectedIdData = options.find(data => data._id === e.target.value)
        if (selectedIdData) {
            onSelect(selectedIdData)
        }
    }

    const iconComponent = (props) => {
        return (
            <ExpandMoreIcon className={props.className + " " + classes.icon} />
        )
    };

    return (
        <FormControl className={classes['.MuiFormControl-root']}>
            {
                label && <Box display='flex'>
                    <Typography className={classes.label}>{label}</Typography>
                    {required && <Typography variant='body01-bold' color='danger.clr-700'>*</Typography>}
                </Box>
            }
            <>
                {
                    loading ? (
                        <SelectSkeleton />
                    ) : (
                        <TitleTooltip
                            placement='bottom'
                            component={value?.name}
                            disableHoverListener={value?.name?.length < 22}
                            positionStyle={{ top: '-10px !important', left: '0px !important', zIndex: 0 }}
                        >
                            <MuiSelect
                                displayEmpty
                                className={`${classes['.MuiSelect-nativeInput']} ${classes['.MuiSelect-root']} ${classes['.MuiSelect-select']}`}
                                sx={{ minWidth: 200, ...(props.sx ? props.sx : {}) }}
                                onChange={handleSelection}
                                renderValue={(selected) => {
                                    const data = options.find((option) => option._id === selected);
                                    if (!selected?.length) {
                                        return (
                                            <Typography
                                                variant="body01-medium"
                                                color="neutral.clr-400"
                                            >
                                                {placeholder}
                                            </Typography>
                                        );
                                    } else {
                                        return data?.name;
                                    }
                                }}
                                value={value?._id}
                                defaultValue={defaultValue?._id}
                                inputProps={{ className: classes.input }}
                                MenuProps={menuProps}
                                IconComponent={iconComponent}
                                {...props}
                                id="select"
                            >
                                {options?.map((option) => (
                                    <MenuItem key={option._id} value={option._id}>
                                        <Box className={classes.overflowText}>
                                            <Typography
                                                className={`${classes.truncatedTypography} ${option.name.length > 22 ? classes.overflown : ''}`}
                                            >
                                                {option.name}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                ))}
                            </MuiSelect>
                        </TitleTooltip>
                    )
                }
            </>
            {
                helperText &&
                <Typography
                    className={classes.supportiveTxt}
                    style={{ color: theme.palette.danger.main }}
                >
                    {helperText}
                </Typography>
            }
        </FormControl>
    );
}

const SelectSkeleton = () => {
    return (
        <Box>
            <Skeleton variant="rectangular" width="200px" height='44px' style={{ borderRadius: '4px' }} />
        </Box>
    )
}