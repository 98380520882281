import React from 'react'
import { makeStyles } from "@mui/styles";
import { Box, Typography } from '@mui/material';
import { useCase } from './Constant';
import { SlidingArrowButton } from 'components/CustomButton';


const useStyles = makeStyles(theme => ({
    parentContainer: {
        background: `
        url('https://assets.languify.in/images/b2cFrame.png') center center no-repeat,
        #2D3142`,
        backgroundSize: 'cover',
        width: '100%',
        display: 'flex',
        flexDirection : 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap : theme.spacing(14.5),
        padding: theme.spacing(15, 37.5),
        [theme.breakpoints.down("lg")]: {
          padding: theme.spacing(10, 15),
          gap : theme.spacing(10),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(10, 10),
        },
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(10, 5),
        },
      },
      container : {
        display : 'flex',
        gap : theme.spacing(5),
        justifyContent : 'space-between',
        [theme.breakpoints.down("lg")]: {
           flexWrap : 'wrap',
           justifyContent : 'center',
           gap : theme.spacing(13)
        },
      },
      boxContainer: {
        borderRadius: theme.spacing(2),
        borderWidth: '1px', 
        borderStyle: 'solid',
        borderImageSource: 'linear-gradient(258.15deg, #62646B -31.15%, #737DA8 108.68%)',
        borderImageSlice: 1, 
        padding : theme.spacing(3,8),
        borderRadius : theme.spacing(2)
      },
      
}))

const UseCase = () => {
    const classes = useStyles();
  return (
    <Box className={classes.parentContainer}>
        <Typography variant='h2-bold' color='neutral.clr-400'>Who can use this?</Typography>
        <Box className={classes.container}>
            {useCase.map((data,index)=> (
                <Box keu={index} display='flex' flexDirection='column' gap='22px' width='50%' minWidth='320px'>
                    <Box className={classes.boxContainer}>
                        <Typography variant='h5-bold' color='b2c.light-900'>
                         {data.content}
                        </Typography>
                    </Box>
                    <img src={data.img} alt='img' />
                </Box>
            ))}
        </Box>
        <Box display='flex' flexDirection='column' gap='40px' alignItems='center'>
            <Typography variant='h2-bold' color='b2c.light-600' style={{textAlign : 'center'}}>
                Get Interview Ready in 30 Minutes!
            </Typography>
            <SlidingArrowButton
                label="Start Preparing Now"
                bgColor='#1961BF'
                variant="outlined"
                width="291px"
                sx={{ textTransform: 'none' }}
            />
        </Box>
    </Box>
  )
}

export default UseCase