import React, { useState, useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import { Box, Typography } from '@mui/material';

import VideoPlayer from 'components/VideoPlayer';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    height : '100%',
    background: `
    url('https://assets.languify.in/images/b2cFrame.png') center center no-repeat,
    linear-gradient(180deg, #2D3142 0%, #737DA8 100%)`,
    backgroundSize: 'cover',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 37.5),
    paddingBottom : theme.spacing(18),
    paddingTop: theme.spacing(33),
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(0, 15),
      paddingTop: theme.spacing(33),
      paddingBottom : theme.spacing(18),
      height: '100%',
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 10),
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: theme.spacing(0, 5),
    },
  },
  mockBox: {
    padding: theme.spacing(1, 17.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
    borderRadius : '4px',
    borderImageSource: 'linear-gradient(90deg, #737DA8 0%, #2D3142 100%)',
    borderImageSlice: 1,
    textAlign : 'center',
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1, 2),
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        padding: theme.spacing(1, 2),
      },
  },
  animatedTextContainer: {
    position: 'relative',
    overflow: 'hidden',
    height: '40px', 
    width: '100%',
    display : 'flex',
    alignItems : 'center'
  },
  animatedText: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    animation: `$slideUp 2s ease-in-out`,
  },
  '@keyframes slideUp': {
    '0%': { transform: 'translateY(100%)', opacity: 0 },
    '30%': { transform: 'translateY(0)', opacity: 1 },
    '70%': { transform: 'translateY(0)', opacity: 1 },
    '100%': { transform: 'translateY(-100%)', opacity: 0 },
  },
}));

const targetRounds = [
  'Any Company . Any Role . Any Interview Round',
  'Google . Product Manager . Case Study Presentation',
  'Amazon . Operations Manager . Team Lead Round',
  'Netflix . Data Scientist . Team Lead Round',
  'Meta . Software Engineer . HR Round',
  'TCS . Software Engineer . HR Round',
  'Infosys . System Engineer . Team Lead Round',
  'Wipro . Analyst . Managerial Round',
  'Google . Product Manager . Round',
];

const B2cHeroSection = () => {
  const classes = useStyles();
  const [currentRound, setCurrentRound] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentRound(prev => (prev + 1) % targetRounds.length);
    }, 1900); 
    return () => clearInterval(interval);
  }, []);

  return (
    <Box className={classes.container}>
      <Box display="flex" flexDirection="column" gap="40px" alignItems="center">
        <Box display="flex" flexDirection="column" gap="16px" alignItems="center">
          <Typography variant="h2-bold" color="shades.clr-white-900" sx={{textAlign : 'center'}}>
            Crack Your Next Interview with an Hour of Power Prep!
          </Typography>
          <Box className={classes.animatedTextContainer}>
            <Typography
              variant="h5-bold"
              color="b2c.green-300"
              className={classes.animatedText}
              key={currentRound} 
            >
              {targetRounds[currentRound]}
            </Typography>
          </Box>
          <Box className={classes.mockBox}>
            <Typography variant="body01-bold" color="b2c.light-600">
              Personalized mock interviews based on resume and JD
            </Typography>
          </Box>
        </Box>
        <Box sx={{ boxShadow: `0px 0px 16px 4px #FFFFFF4D`, borderRadius: '12px' }}>
          <VideoPlayer
            link={"https://assets.languify.in/video/alakh_pandey.mp4"}
            thumbnail={"https://assets.languify.in/images/b2cHeroThumbnail.png"}
            style={{ width: '100%' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default B2cHeroSection;
