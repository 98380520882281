import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    animation: {
        animation: '$ripple 2.0s 0.2s infinite',
    },
    '@keyframes ripple': {
        '20%': {
            boxShadow: '0px 0px 0px 6px #fff, 0px 0px 0px 8px #d9d9d9',
        },
        '50%': {
            boxShadow: '0px 0px 0px 6px #fff, 0px 0px 0px 8px #d9d9d9, 0px 0px 0px 14px #fff, 0px 0px 2px 16px #888',
        },
        '100%': {
            boxShadow: '0px 0px 0px 6px #fff, 0px 0px 0px 8px #d9d9d9, 0px 0px 0px 14px #fff, 0px 0px 2px 16px #888, 0px 0px 0px 22px #fff, 0px 0px 5px 24px #ddd',
        },
    },
    concentricCircles: {
        width: '80px',
        height: '80px',
        marginLeft: '50%',
        borderRadius: '50%',
        transform: 'translateX(-50%)',
        transition: 'all 0.2s ease-in-out',
        backgroundSize: '80px 80px',
        backgroundImage: `url(${process.env.REACT_APP_ASSETS_URL}/images/ai_interviewer.svg)`,
        backgroundRepeat: 'no-repeat',
        '&:hover': {
            transform: 'translateX(-50%) scale(1.2)',
        },
        [theme.breakpoints.down('sm')]: {
            width: '50px', height: '50px',
            backgroundSize: '50px 50px',
        }
    },
}));

const SpeechAnimation = ({ play = true, ...props }) => {
    const classes = useStyles();

    return (
        <div
            className={`${classes.concentricCircles} ${play ? classes.animation : ''}`}
            {...props}
        >
        </div>
    );
}

export default SpeechAnimation;
