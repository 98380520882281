import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles';

import { feedbackSteps } from './Constant';
import OverviewSteps from 'components/OverviewSteps';

const useStyles = makeStyles((theme) => ({
    container : {
        padding: theme.spacing(21.5, 37.5),
        backgroundColor : theme.palette.neutral['clr-50'],
        marginTop : theme.spacing(2),
        width : "100%",
        display : 'flex',
        justifyContent:'center',
        alignItems:'center',
        [theme.breakpoints.down("lg")]: {
          padding: theme.spacing(14, 15),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(14, 10),
        },
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(14, 5),
        },
    }
}))

const B2cFeedbackOverview = () => {
    const classes = useStyles();
    return (
      <Box className={classes.container}>
          <OverviewSteps data={feedbackSteps} />
      </Box>
    )
}

export default B2cFeedbackOverview