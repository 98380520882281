import React from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { Box, Typography, Button } from '@mui/material';

import rightArrowIcon from '../assets/rightArrowWhite.svg'
import LoadingButton from '@mui/lab/LoadingButton';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
    display: 'flex',
    gap: theme.spacing(1),
    textTransform: "none",
    height: '36px',
    '& .MuiButton-endIcon': {
      marginLeft: '0px'
    },
    '& .MuiButton-startIcon': {
      marginRight: '0px'
    }
  },
  iconBtn: {
    padding: theme.spacing(1, 2),
    minWidth: 'fit-content',
  },
  loadingButton: {
    ...theme.typography['body01-bold'],
  },
  small: {
    height: '36px',
    padding: theme.spacing(2, 4),
    ...theme.typography['body01-bold'],
  },
  medium: {
    height: '48px',
    padding: theme.spacing(3, 6),
    ...theme.typography['h6-medium'],
  },
  large: {
    height: '48px',
    padding: theme.spacing(3, 7),
    ...theme.typography['h6-medium'],
  },
  contained: {
    ...theme.typography['body01-bold'],
    '&:hover': {
      backgroundColor: theme.palette.primary['clr-400']
    }
  },
  outlined: {
    ...theme.typography['body01-bold'],
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.shades['clr-white-900'],
    '&:hover': {
      borderWidth: '1px',
      backgroundColor: theme.palette.primary['clr-50']
    }
  },
  outlinedIconBtn: {
    borderColor: theme.palette.neutral['clr-200'],
    color: theme.palette.shades['clr-black-900'],
    '&:hover': {
      backgroundColor: theme.palette.primary['clr-50']
    }
  },
  extendContainer: {
    cursor: "pointer",
    border: `2px solid`,
    borderColor: theme.palette.primary['clr-500'],
    borderRadius: "8px",
    height: "48px",
    backgroundColor: theme.palette.shades['clr-white-900'],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
    transition: "width 0.1s, border 0.1s, transform 0.1s",
    left: 0,
    padding: theme.spacing(0),
    "&:hover": {
      backgroundColor: theme.palette.shades['clr-white-900'],
    }
  },
  hoverContainer: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(1),
    alignItems: "center",
    background: `linear-gradient(278.27deg,#4B6CB7 0%,#002759 112.69%),
                linear-gradient(0deg,#FFFFFF,#FFFFFF)`,
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2.5, 6, 2.5, 6),
    borderRadius: '8px',
    textTransform: 'none',
    transition: 'background 0.3s cubic-bezier(0.45, 1.45, 0.8, 1), color 0.3s cubic-bezier(0.45, 1.45, 0.8, 1)',
    overflow: "hidden",
    "&::before": {
      content: `""`,
      position: "absolute",
      top: 0,
      left: -20,
      height: "50px",
      width: 19,
      opacity: 0.25,
      transform: "skew(-45deg)",
      background: `white`,
      animation: '$load 2.2s cubic-bezier(0.45, 1.45, 0.8, 1) infinite',
    },
    "&:hover": {
      background: "inherit",
      [theme.breakpoints.down('md')]: {
        background: `linear-gradient(278.27deg,#4B6CB7 0%,#002759 112.69%),
                linear-gradient(0deg,#FFFFFF,#FFFFFF) !important`
      },
      "& $hoverText": {
        color: theme.palette.primary.main,
        [theme.breakpoints.down('md')]: {
          color: theme.palette.shades['clr-white-900']
        }
      },
    },
  },
  '@keyframes load': {
    '0%': {
      transform: "translate3d(-30px, 0, 0) skew(-45deg)",
    },
    '13.64%': { // 300ms of 2200ms total duration (300ms / 2200ms)
      transform: "translate3d(280px, 0, 0) skew(-45deg)",
    },
    '50%': { // 300ms animation + 800ms delay (13.64% + 36.36% = 50%)
      transform: "translate3d(280px, 0, 0) skew(-45deg)",
    },
    '63.64%': { // 300ms back (13.64% + 36.36% + 13.64% = 63.64%)
      transform: "translate3d(-30px, 0, 0) skew(-45deg)",
    },
    '100%': { // Complete the 800ms delay again (63.64% + 36.36% = 100%)
      transform: "translate3d(-30px, 0, 0) skew(-45deg)",
    }
  },
  hoverText: {
    color: theme.palette.shades['clr-white-900'],
    transition: 'color 0.3s ease',
    [theme.breakpoints.down('md')]: {
      transition: "none"
    }
  },
  extendBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
  },
  extendText: {
    minWidth: "100px",
    position: "relative",
    zIndex: 1,
    transition: "transform 0.1s ease-in-out",
    textTransform: 'none',
  },
  imageContainer: {
    position: "absolute",
    left: "100%",
    top: "50%",
    transform: "translateX(-100%) translateY(-50%)",
    transition: "transform 0.1s ease-in-out, opacity 0.1s ease-in-out",
    opacity: 0,
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.down('md')]: {
      display: "none"
    }
  },
  image: {
    maxWidth: "24px",
  },
  extendBoxContainer: {
    justifyContent: "space-between",
    "& $extendText": {
      transform: "translateX(-10px)",
      [theme.breakpoints.down('md')]: {
        transform: "none"
      }
    },
    "&:hover $imageContainer": {
      left: "calc(100% - 16px)",
      transform: "translateX(-100%) translateY(-50%)",
      opacity: 1,
      [theme.breakpoints.down('md')]: {
        transform: "none"
      }
    }
  },
  ".css-19kx6zu-MuiButtonBase-root-MuiButton-root.Mui-disabled": {
    background: theme.palette.neutral.disabled
  },
  buttonContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    transition: 'all 0.3s ease',
    '&:hover $label': {
      transform: 'translateX(-10px)',
    },
    '&:hover $hoverImage': {
      opacity: 1,
      transform: 'translateX(10px)',
      right: '60px',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.3s ease',
  },
  hoverImage: {
    position: 'absolute',
    right: '100px',
    opacity: 0,
    transform: 'translateX(-20px)',
    transition: 'all 0.3s ease',
  },

}));


/**
 * Primary/Secondary Button component
 *
 * @param {Object} props - The properties object.
 * @param {string} props.className - custom class name(s) to apply to the button.
 * @param {object} props.sx - Additional styles to apply to the button.
 * @param {boolean} props.disabled - If `true`, the button is disabled.
 * @param {'small' | 'medium' | 'large'} props.size - The size of the button, must be one of 
  'small', 'medium', or 'large'.
 * @param {'contained' | 'outlined'} props.variant - The variant of the button, must be one of 
  'contained' or 'outlined'.
 * @param {function} [props.onClick] - The click event handler.
 * @param {React.ReactNode} [props.startIcon] - Element to be placed before the children.
 * @param {React.ReactNode} [props.endIcon] - Element to be placed after the children.
 * @returns {JSX.Element} The rendered PrimaryButton component.
 */

export default function CustomButton({
  disabled = false,
  onClick = () => { },
  size = 'small',
  variant = 'contained',
  startIcon = <></>,
  ...props
}) {
  const classes = useStyles();

  return (
    <Button
      disableElevation
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      className={`${classes.root} ${classes[variant]} ${classes[size]}`}
      {...props}>
    </Button>
  );
}


/**
 * IconButton component
 *
 * @param {Object} props - The properties object.
 * @param {string} props.className - custom class name(s) to apply to the button.
 * @param {string} width - Additional styles to apply to the button.
 * @param {string} label & icon - Additional styles to apply to the button.
 * @param {boolean} props.disabled - If `true`, the button is disabled.
 * @param {function} [props.onClick] - The click event handler.
 * @returns {JSX.Element} The rendered PrimaryButton component.
 */

export function IconButton({
  label,
  icon,
  color,
  bgColor,
  disabled = false,
  onClick = () => { },
  size = "small",
  variant = "contained",
  hoveredVariant = "contained",
  width = "141px",
  sx = {},
  ...props
}) {
  const classes = useStyles();
  const [hovered, setHovered] = React.useState(false);

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const extendedWidth = screenWidth < 900 ? `${parseInt(width, 10)}` : `${parseInt(width, 10) + 28}px`;

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant={hovered ? hoveredVariant : variant}
      size={size}
      style={{ width: hovered ? extendedWidth : width, backgroundColor: bgColor }}
      sx={{ ...sx }}
      className={`${classes.extendContainer} ${hovered ? classes.extendBoxContainer : ''}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...props}
    >
      <Box className={classes.extendBox}>
        <Typography variant='h6-medium' color={color} className={classes.extendText}>
          {label}
        </Typography>
        <Box className={classes.imageContainer}>
          <img src={icon} alt='book' className={classes.image} />
        </Box>
      </Box>
    </Button>
  );
}

/**
 * HoverButton component
 *
 * @param {Object} props - The properties object.
 * @param {string} props.className - custom class name(s) to apply to the button.
 * @param {string} label & icon - Additional styles to apply to the button.
 * @param {boolean} props.disabled - If `true`, the button is disabled.
 * @param {function} [props.onClick] - The click event handler.
 * @returns {JSX.Element} The rendered PrimaryButton component.
 */

export function HoverButton({ label, color, icon, disabled = false, onClick = () => { }, ...props }) {
  const classes = useStyles();

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className={classes.hoverContainer}
      {...props}
      variant='contained'
    >
      <Typography variant='h6-medium' className={classes.hoverText} color={color}>
        {label}
      </Typography>
      {icon && <img src={icon} width={24} alt='icon' style={{ transition: 'none' }} />}
    </Button>
  );
}

// SlidingArrowButton

export function SlidingArrowButton({ width, bgColor, variant, label, onClick = () => { }, ...props }) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Button
      variant={variant}
      style={{
        width: width,
        backgroundColor: bgColor,
        padding: theme.spacing(4, 15),
        border: 'none',
      }}
      className={`${classes.buttonContainer} ${classes.buttonHovered}`}
      onClick={onClick}
      {...props}
    >
      <span className={classes.label}>
        <Typography variant="h6-medium" color="#FFFFFF">
          {label}
        </Typography>
      </span>
      <img
        src={rightArrowIcon}
        width={14}
        alt="icon"
        className={classes.hoverImage}
      />
    </Button>
  );
}

/**
 * CustomLoadingButton component
 *
 * @param {Object} props - The properties object.
 * @param {string} props.className - custom class name(s) to apply to the button.
 * @param {object} props.sx - Additional styles to apply to the button.
 * @param {boolean} props.disabled - If `true`, the button is disabled.
 * @param {'small' | 'medium' | 'large'} props.size - The size of the button, must be one of 'small', 'medium', or 'large'.
 * @param {function} [props.onClick] - The click event handler.
 * @param {boolean} [props.loading=false] - If `true`, the button shows a loading spinner.
 * @returns {JSX.Element} The rendered PrimaryButton component.
 */

export function CustomLoadingButton({
  disabled = false, onClick = () => { }, loading = false, size = 'small', variant = 'contained', ...props
}) {
  const classes = useStyles();

  return (
    <LoadingButton
      disableElevation
      disabled={disabled}
      onClick={onClick}
      loading={loading}
      variant={variant}
      className={`${classes.root} ${classes[variant]} ${classes.loadingButton} ${classes[size]}`}
      {...props}
    />
  );
}